import React from 'react';
import { motion } from 'framer-motion';
import { HeroHighlight, Highlight } from '@/components/ui/hero-highlight';
import { Button } from '@/components/ui/button';
import { DownloadIcon } from '@radix-ui/react-icons';
import { NavLink as Link } from 'react-router-dom';
import { VideoPlaceholder, Video } from '@/components/sections/home/Video';
export function Hero() {
    return (
        <section className="relative z-10 ">
            <HeroHighlight containerClassName="h-fit px-4 py-64">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: [20, -5, 0] }}
                    transition={{ duration: 0.5, ease: [0.4, 0.0, 0.2, 1] }}
                    className="text-center"
                >
                    <h1 className="text-3xl px-4 sm:text-4xl lg:text-5xl font-bold text-foreground max-w-4xl leading-tight sm:leading-tight lg:leading-tight mx-auto mb-6">
                        Elevate Your Productivity with Mantra AI
                    </h1>
                    <p className="text-xl sm:text-3xl font-semi-bold text-foreground mb-8">
                        Your intelligent companion for <Highlight className="text-foreground">peak performance and well-being</Highlight>
                    </p>
                    <div className="flex flex-col sm:flex-row justify-center gap-4 mt-8 px-5 items-center nowrap" >
                        <Link to="/download">
                            <Button size="lg">
                                <DownloadIcon className="mr-2 h-4 w-4" /> Download Now
                            </Button>
                        </Link>
                        <Button size="lg" variant="outline" className="w-fit">
                            Learn More
                        </Button>
                    </div>
                </motion.div>
                <VideoPlaceholder />
            </HeroHighlight>
            <Video />
        </section>
    );
}

export default Hero;
