import React, {useState, useEffect} from 'react';
import { Button } from '@/components/ui/button';
import { useTheme } from '@/components/layout/theme-provider';
import Particles from '@/components/ui/particles';
const CallToActionSection: React.FC = () => {
    const { theme } = useTheme();
    const [color, setColor] = useState('#ffffff');

    useEffect(() => {
        setColor(theme === 'dark' ? '#ffffff' : '#000000');
        console.log('theme', theme);
    }, [theme]);

    return (
        <section className='py-16 text-foreground relative  min-h-[800px] h-[80vh] flex items-center justify-center bg-background'>
        <Particles className="absolute inset-0 z-0 pointer-events-none" quantity={400} ease={100} size={0.6} staticity={50} color={color} refresh />
            <div className="container mx-auto px-4 text-center">
                <h2 className="text-3xl font-bold mb-4">Ready to Transform Your Productivity?</h2>
                <p className="text-xl mb-8">Join thousands of professionals already using Mantra AI to achieve their goals.</p>
                <a href="#hero">

                <Button size="lg" variant="secondary" className='z-[1000]' >
                    Download Free Today
                </Button>
                </a>

            </div>
        </section>
    );
};

export default CallToActionSection;