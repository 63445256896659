import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

import Header from '@/components/layout/mantra-header';
import Footer from '@/components/layout/mantra-footer';

import '@/styles/theme.css';
import { ThemeProvider } from '@/components/layout/theme-provider';

import Home from '@/app/Home';
import Download from '@/app/Download';
import Privacy from '@/app/Privacy';
import Pricing from '@/app/Pricing';

const useScrollToHash = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.querySelector(location.hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);
};

function ScrollToTop() {
    useScrollToHash();

    return null;
}

function App() {
    return (
        <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
            <Router>
                <ScrollToTop />
                <div className="flex flex-col min-h-screen w-full max-w-[100vw]">
                    <Header />
                    <main className="flex-grow overflow-x-hidden">
                        <div className="w-full pt-10">
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="/download" element={<Download />} />
                                <Route path="/privacy" element={<Privacy />} />
                                <Route path="/pricing" element={<Pricing />} />
                            </Routes>
                        </div>
                    </main>
                    <Footer />
                </div>
            </Router>
        </ThemeProvider>
    );
}

export default App;
