
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Button } from '@/components/ui/button';
import {NavLink as Link} from 'react-router-dom'
import { Sparkles, ArrowRight } from 'lucide-react';
import Particles from '@/components/ui/particles';
import { useTheme } from '@/components/layout/theme-provider';

export function CallToAction() {
    const { theme } = useTheme();
    const [color, setColor] = useState('#ffffff');

    useEffect(() => {
        setColor(theme === 'dark' ? '#000000' : '#ffffff');
    }, [theme]);

    return (
        <section className='py-20 relative overflow-hidden min-h-[500px] h-[50vh] flex items-center justify-center bg-background'>
            <Particles className="absolute inset-0 z-10" quantity={150} ease={100} size={0.6} staticity={50} color={color} refresh />
            <div className="container mx-auto px-4 relative z-20">
                <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }} className="text-center">
                    <h2 className="text-4xl md:text-5xl font-bold mb-6 text-primary bg-clip-text text-transparent bg-gradient-to-r from-primary to-primary-foreground h-fit">
                        Elevate Your Productivity
                    </h2>
                    <p className="text-lg md:text-xl mb-8 max-w-2xl mx-auto text-muted-foreground">
                        Unlock your potential and achieve peak performance with our innovative solutions.
                    </p>
                    <div className="flex flex-col sm:flex-row justify-center gap-4">
                        <Link to="/download/" >
                            <Button size="lg" className="bg-primary text-primary-foreground hover:bg-primary/90 transition-colors duration-300 group">
                                Start Free Trial
                                <Sparkles className="ml-2 h-5 w-5 transition-transform group-hover:rotate-12" />
                            </Button>
                        </Link>
                        <Button
                            size="lg"
                            variant="outline"
                            className="border-primary text-primary hover:bg-primary/10 transition-colors duration-300 group"
                        >
                            Schedule Demo
                            <ArrowRight className="ml-2 h-5 w-5 transition-transform group-hover:translate-x-1" />
                        </Button>
                    </div>
                </motion.div>
            </div>
        </section>
    );
}

export default CallToAction;
