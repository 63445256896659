
            function handleThemeChange() {
                const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
                document.documentElement.classList.toggle('dark', isDarkMode);
            }

            // Initial call
            handleThemeChange();

            // Listen for changes
            window.matchMedia('(prefers-color-scheme: dark)').addListener(handleThemeChange);
        