import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { DownloadIcon } from '@radix-ui/react-icons';
import AppleLogo from '@/assets/apple-logo.svg?react';
import WindowsLogo from '@/assets/windows-logo.svg?react';
import { Platform } from '@/lib/platformUtils';

interface ReleaseAsset {
    name: string;
    browser_download_url: string;
    size: number;
}

interface ReleaseData {
    tag_name: string;
    assets: ReleaseAsset[];
}

interface DownloadSectionProps {
    releaseData: ReleaseData | null;
    detectedPlatform: Platform;
}

interface DownloadOption {
    name: string;
    icon: JSX.Element;
    versions: { name: string; size: string; url: string }[];
}

const DownloadSection: React.FC<DownloadSectionProps> = ({ releaseData, detectedPlatform }) => {
    const [osType, setOsType] = useState<'windows' | 'mac'>('windows');

    useEffect(() => {
        setOsType(detectedPlatform === 'mac-arm' || detectedPlatform === 'mac-intel' ? 'mac' : 'windows');
    }, [detectedPlatform]);

    const [downloadOptions, setDownloadOptions] = useState<{ [key: string]: DownloadOption }>({
        windows: {
            name: 'Windows',
            icon: <WindowsLogo className="w-16 h-16 mb-4 fill-foreground" />,
            versions: [],
        },
        mac: {
            name: 'macOS',
            icon: <AppleLogo className="w-16 h-16 mb-4 text-primary fill-foreground" />,
            versions: [],
        },
    });

    useEffect(() => {
        if (releaseData) {
            const assets = releaseData.assets;
            const windowsAsset = assets.find((asset) => asset.name.endsWith('.exe'));
            const macArmAsset = assets.find((asset) => asset.name.includes('arm64') && asset.name.endsWith('.dmg'));
            const macIntelAsset = assets.find((asset) => !asset.name.includes('arm64') && asset.name.endsWith('.dmg'));

            setDownloadOptions((prevOptions) => ({
                windows: {
                    ...prevOptions.windows,
                    versions: [
                        {
                            name: 'Windows 10/11 (64-bit)',
                            size: windowsAsset ? `${(windowsAsset.size / 1024 / 1024).toFixed(1)}MB` : 'Unknown',
                            url: windowsAsset?.browser_download_url || '#',
                        },
                    ],
                },
                mac: {
                    ...prevOptions.mac,
                    versions: [
                        {
                            name: 'macOS (Apple Silicon)',
                            size: macArmAsset ? `${(macArmAsset.size / 1024 / 1024).toFixed(1)}MB` : 'Unknown',
                            url: macArmAsset?.browser_download_url || '#',
                        },
                        {
                            name: 'macOS (Intel)',
                            size: macIntelAsset ? `${(macIntelAsset.size / 1024 / 1024).toFixed(1)}MB` : 'Unknown',
                            url: macIntelAsset?.browser_download_url || '#',
                        },
                    ],
                },
            }));
        }
    }, [releaseData]);

    const DownloadCard = ({ os }: { os: string }) => (
        <Card className='mb-4'>
            <CardContent className="p-6">
                <div className="flex flex-col items-center">
                    {downloadOptions[os].icon}
                    <h2 className="text-2xl font-semibold mb-4">Download for {downloadOptions[os].name}</h2>
                    {downloadOptions[os].versions.map((version, index) => (
                        <div key={index} className="w-fit mb-4">
                            <Button size="lg" className="w-fit mb-2" disabled={version.url === '#'}>
                                <a href={version.url} download className="w-full flex flex-row">
                                    <DownloadIcon className="mr-2 h-4 w-4" />
                                    {version.url === '#' ? 'Coming Soon!' : 'Download for ' + version.name}
                                </a>
                            </Button>
                            <p className="text-sm text-center text-muted-foreground">
                                Version {releaseData?.tag_name || 'Latest'} | {version.size}
                            </p>
                        </div>
                    ))}
                </div>
            </CardContent>
        </Card>
    );

    return (
        <div id="download" className="w-full h-fit min-h-fit min-w-fit bg-secondary" >
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
                className="max-w-4xl mx-auto px-4 flex justify-center"
            >
                {/* Large screen layout */}
                <div className="hidden lg:grid md:grid grid-cols-2 gap-8">
                    <DownloadCard os="windows" />
                    <DownloadCard os="mac" />
                </div>

                {/* Small screen layout */}
                <div className="lg:hidden md:hidden max-w-xl">
                    <Tabs value={osType} onValueChange={(value) => setOsType(value as 'windows' | 'mac')}>
                        <TabsList className="grid w-full grid-cols-2">
                            <TabsTrigger value="windows" className="gap-1">
                                <WindowsLogo className="w-4 h-4" /> Windows
                            </TabsTrigger>
                            <TabsTrigger value="mac" className="gap-1">
                                <AppleLogo className="w-4 h-4" /> macOS
                            </TabsTrigger>
                        </TabsList>
                        {['windows', 'mac'].map((os) => (
                            <TabsContent key={os} value={os}>
                                <DownloadCard os={os} />
                            </TabsContent>
                        ))}
                    </Tabs>
                </div>
            </motion.div>
        </div>
    );
};

export default DownloadSection;