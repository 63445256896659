import React from 'react';
import { Pricing } from '@/components/sections/pricing/Pricing';
import { ArrowRight } from 'lucide-react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";

const FAQ = () => (
  <section className="py-16 bg-secondary/20">
    <div className="container mx-auto px-4">
      <h2 className="text-3xl font-bold text-center mb-8">Frequently Asked Questions</h2>
      <Accordion type="single" collapsible className="w-full">
        {[
          { q: "What payment methods do you accept?", a: "We accept all major credit cards, PayPal, and bank transfers." },
          { q: "Can I switch plans later?", a: "Yes, you can upgrade or downgrade your plan at any time." },
          { q: "Is there a free trial?", a: "Yes, we offer a 14-day free trial on all paid plans." },
          { q: "How does the AI recommendation feature work?", a: "Our AI analyzes your usage patterns to suggest personalized improvements." }
        ].map((item, index) => (
          <AccordionItem key={index} value={`item-${index}`}>
            <AccordionTrigger>{item.q}</AccordionTrigger>
            <AccordionContent>{item.a}</AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  </section>
);

const Testimonials = () => (
  <section className="py-16 bg-primary/5">
    <div className="container mx-auto px-4">
      <h2 className="text-3xl font-bold text-center mb-8">What Our Customers Say</h2>
      <div className="grid md:grid-cols-3 gap-8">
        {[
          { name: "Alex Johnson", role: "Freelancer", quote: "This app has revolutionized my productivity. Worth every penny!" },
          { name: "Sarah Lee", role: "Small Business Owner", quote: "The team collaboration tools are a game-changer for us." },
          { name: "Mike Brown", role: "Student", quote: "As a student, the pro plan offers more than enough to keep me organized." }
        ].map((item, index) => (
          <Card key={index}>
            <CardHeader>
              <CardTitle>{item.name}</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="italic mb-4">"{item.quote}"</p>
              <p className="text-sm text-muted-foreground">{item.role}</p>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  </section>
);

const CallToAction = () => (
  <section className="py-20 bg-primary text-primary-foreground">
    <div className="container mx-auto px-4 text-center">
      <h2 className="text-3xl font-bold mb-4">Ready to Get Started?</h2>
      <p className="mb-8">Choose a plan that works for you and start boosting your productivity today.</p>
      <Button variant="secondary" size="lg" asChild>
        <a href="#pricing" className="inline-flex items-center">
          View Plans <ArrowRight className="ml-2 h-4 w-4" />
        </a>
      </Button>
    </div>
  </section>
);

export default function PricingPage() {
  return (
    <>
      <div id="pricing">
        <Pricing />
      </div>
      <Testimonials />
      <FAQ />
    </>
  );
}