import React from 'react';
import { Shield, Lock, Eye, Database, Trash2 } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import {Button} from '@/components/ui/button';
const PrivacyPage = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-6 text-center">Mantra Privacy Policy</h1>
      <p className="text-lg mb-8 text-center">
        At Mantra, we prioritize your privacy and data security. Here&rsquo;s how we protect your information:
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-12 items-center">
        <Card className='max-w-2xl'>
          <CardHeader>
            <CardTitle className="flex items-center">
              <Shield className="mr-2" /> Data Protection
            </CardTitle>
          </CardHeader>
          <CardContent>
            Your data is encrypted and securely stored on your device. We use industry-standard encryption methods to ensure your information remains private.
          </CardContent>
        </Card>

        <Card className='max-w-2xl'>
          <CardHeader>
            <CardTitle className="flex items-center">
              <Lock className="mr-2" /> Local Storage
            </CardTitle>
          </CardHeader>
          <CardContent>
            Most of your data is stored locally on your device. This means you have full control over your information and can easily delete it at any time.
          </CardContent>
        </Card>

        <Card className='max-w-2xl'>
          <CardHeader>
            <CardTitle className="flex items-center">
              <Eye className="mr-2" /> Transparency
            </CardTitle>
          </CardHeader>
          <CardContent>
            We&apos;re committed to being transparent about our data practices. You can always view what data is being collected and how it&apos;s being used within the app.
          </CardContent>
        </Card>
      </div>

      <Accordion type="single" collapsible className="mb-12">
        <AccordionItem value="item-1">
          <AccordionTrigger>What data does Mantra collect?</AccordionTrigger>
          <AccordionContent>
            Mantra collects data on your active windows, website URLs, focus flow durations, and task completion status. We also track user-set productivity goals and optional mood and energy levels. This data is used to provide you with insights and improve your productivity.
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-2">
          <AccordionTrigger>How is my data stored and protected?</AccordionTrigger>
          <AccordionContent>
            Your data is stored locally on your device using an encrypted SQLite database. We use industry-standard encryption methods to ensure your information remains private and secure.
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-3">
          <AccordionTrigger>Can I delete my data?</AccordionTrigger>
          <AccordionContent>
            Yes, you can easily delete your data at any time from within the app. We also have data retention policies in place to automatically delete old data after a certain period.
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-4">
          <AccordionTrigger>Does Mantra share my data with third parties?</AccordionTrigger>
          <AccordionContent>
            No, Mantra does not share your personal data with any third parties. Your data remains on your device and is used solely to provide you with productivity insights and features within the app.
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <div className="text-center">
        <h2 className="text-2xl font-bold mb-4">Your Privacy, Your Control</h2>
        <p className="mb-6">
          We believe in giving you full control over your data. You can always adjust your privacy settings, export your data, or delete it entirely from within the app.
        </p>
        <div className="flex justify-center gap-8 mb-12">
          <Card className="w-48 hover:shadow-lg transition-shadow duration-300">
            <CardContent className="flex flex-col items-center justify-center h-full p-6">
              <Database className="w-12 h-12 mb-4 text-blue-500" />
              <p className="text-lg font-semibold mb-2">Export Your Data</p>
              {/* <Button variant="outline" size="sm">
                Export
              </Button> */}
            </CardContent>
          </Card>
          <Card className="w-48 hover:shadow-lg transition-shadow duration-300">
            <CardContent className="flex flex-col items-center justify-center h-full p-6">
              <Trash2 className="w-12 h-12 mb-4 text-red-500" />
              <p className="text-lg font-semibold mb-2">Delete Your Data</p>
              {/* <Button variant="outline" size="sm" className="text-red-500 border-red-500 hover:bg-red-50">
                Delete
              </Button> */}
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};export default PrivacyPage;