import React, { useState } from 'react';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Switch } from '@/components/ui/switch';
import { CheckIcon } from 'lucide-react';
import { NavLink as Link } from 'react-router-dom';

const pricingPlans = [
    {
        name: 'Basic',
        monthlyPrice: 0,
        annualPrice: 0,
        features: ['Single device sync', 'Basic features', 'Limited AI recommendations', 'Community support'],
        isPopular: true,
        available: true,
    },
    {
        name: 'Premium',
        monthlyPrice: 9.99,
        annualPrice: 99.99,
        features: ['Sync up to 3 devices', 'Basic AI recommendations', '5 meditation sessions/month', 'Standard support'],
        isPopular: false,
        available: false,
    },
    {
        name: 'Pro',
        monthlyPrice: 19.99,
        annualPrice: 199.99,
        features: ['Unlimited device sync', 'Advanced AI recommendations', 'Unlimited meditation sessions', 'Priority support', 'Advanced analytics'],
        isPopular: false,
        available: false,
    },
    {
        name: 'Enterprise',
        monthlyPrice: 49.99,
        annualPrice: 499.99,
        features: ['All Pro features', 'Team collaboration tools', 'Custom integrations', 'Dedicated account manager', 'On-demand training'],
        isPopular: false,
        available: false,
    },
];

export function Pricing() {
    const [isAnnual, setIsAnnual] = useState(false);

    const calculateSavings = (monthlyPrice: number, annualPrice: number) => {
        const annualCostIfMonthly = monthlyPrice * 12;
        const savings = annualCostIfMonthly - annualPrice;
        return savings.toFixed(2);
    };

    return (
        <section className="py-20 bg-background">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold text-center mb-8 text-foreground">Choose Your Plan</h2>
                <div className="flex items-center justify-center mb-8">
                    <span className="mr-2 text-foreground">Monthly</span>
                    <Switch checked={isAnnual} onCheckedChange={setIsAnnual} />
                    <span className="ml-2 text-foreground">Annual</span>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    {pricingPlans.map((plan, index) => (
                        <Card key={index} className={`flex flex-col ${plan.isPopular ? 'border-primary' : ''}`}>
                            <CardHeader>
                                <CardTitle>{plan.name}</CardTitle>
                                {plan.isPopular && <span className="text-primary text-sm">Most Popular</span>}
                            </CardHeader>
                            <CardContent className="flex-grow">
                                <div className="mb-4">
                                    <p className="text-3xl font-bold">
                                        {plan.monthlyPrice === 0 ? (
                                            'Free'
                                        ) : (
                                            <>
                                                ${isAnnual ? plan.annualPrice.toFixed(2) : plan.monthlyPrice.toFixed(2)}
                                                <span className="text-sm font-normal">/{isAnnual ? 'year' : 'month'}</span>
                                            </>
                                        )}
                                    </p>
                                    {isAnnual && plan.monthlyPrice > 0 && (
                                        <p className="text-sm text-muted-foreground">
                                            <span className="line-through">${(plan.monthlyPrice * 12).toFixed(2)}</span> Save $
                                            {calculateSavings(plan.monthlyPrice, plan.annualPrice)}
                                        </p>
                                    )}
                                </div>
                                <ul className="space-y-2">
                                    {plan.features.map((feature, featureIndex) => (
                                        <li key={featureIndex} className="flex items-center">
                                            <CheckIcon className="mr-2 h-4 w-4 text-primary" />
                                            <span>{feature}</span>
                                        </li>
                                    ))}
                                </ul>
                            </CardContent>
                            <CardFooter>
                                <Button className="w-full" variant={plan.isPopular ? 'default' : 'outline'} disabled={!plan.available}>
                                    <Link to="/download" className="w-full">
                                        {!plan.available ? 'Coming Soon' : plan.monthlyPrice === 0 ? 'Get Started' : 'Subscribe Now'}
                                    </Link>
                                </Button>
                            </CardFooter>
                        </Card>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Pricing;
