import React from 'react';
import Hero from '@/components/sections/home/Hero';
import Features from '@/components/sections/home/Features';
import HowItWorks from '@/components/sections/home/Steps';
import Testimonials from '@/components/sections/home/Testimonials';
import CallToAction from '@/components/sections/home/CallToAction';
import Contact from '@/components/sections/home/Contact';

export default function Home() {
    return (
        <>
        
            <div id="hero">
                <Hero />
            </div>
            <div id="features">
                <Features />
            </div>
            <div id="how-it-works">
                <HowItWorks />
            </div>
            <div id="testimonials">
                <Testimonials />
            </div>
            <div id="cta">
                <CallToAction />
            </div>
            <div id="contact">
                <Contact />
            </div>
        </>
    );
}
