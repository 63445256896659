import React from 'react';
import { cn } from '@/lib/utils';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from '@/components/ui/card';
import { Monitor, Music, User, CheckSquare, ArrowLeftRight, Calendar } from 'lucide-react';
import { VideoPlaceholder } from '@/components/sections/home/Video';

const FeatureCard = ({ title, description, icon: Icon, className }) => (
    <Card className={cn('group flex flex-col h-full transition-all hover:shadow-lg min-h-fit', className)}>
        <CardHeader className="relative p-4 sm:p-6">
            <div className="absolute inset-0 bg-gradient-to-br from-primary/20 to-primary/5 opacity-0 group-hover:opacity-100 transition-opacity" />
            <div className="w-10 h-10 sm:w-12 sm:h-12 rounded-full bg-primary/10 flex items-center justify-center mb-2 sm:mb-4 relative z-10 group-hover:scale-110 transition-transform">
                <Icon className="h-5 w-5 sm:h-6 sm:w-6 text-primary" />
            </div>
            <CardTitle className="text-lg sm:text-xl font-semibold relative z-10 group-hover:translate-x-1 transition-transform">{title}</CardTitle>
        </CardHeader>
        <CardContent className="hidden sm:block flex-grow p-4 sm:p-6 pt-0 sm:pt-2">
            <CardDescription className="text-muted-foreground group-hover:text-foreground transition-colors">{description}</CardDescription>
        </CardContent>
    </Card>
);

const features = [
    {
        title: 'AI Device Tracking',
        description:
            'Gain insights into your device usage patterns with our advanced AI. Optimize your digital habits for enhanced productivity and well-being.',
        icon: Monitor,
        className: 'md:col-span-2 lg:col-span-3',
    },
    {
        title: 'Personalized Playlists',
        description:
            'Boost your focus with AI-curated music tailored to your work style and preferences. Let the perfect soundtrack drive your productivity.',
        icon: Music,
        className: 'md:col-span-1 lg:col-span-2',
    },
    {
        title: 'Mindfulness Assistant',
        description:
            'Achieve mental clarity with AI-guided meditation and breathing exercises. Tailor sessions to your mood and schedule for optimal relaxation.',
        icon: User,
        className: 'md:col-span-1 lg:col-span-2',
    },
    {
        title: 'Smart Task Management',
        description:
            'Prioritize your to-do list effortlessly with our AI-powered task organizer. Stay on top of deadlines and boost your efficiency.',
        icon: CheckSquare,
        className: 'md:col-span-2 lg:col-span-3',
    },
    {
        title: 'Focus Optimization',
        description:
            'Minimize distractions with intelligent task switching recommendations. Our AI helps you maintain flow and maximize productivity.',
        icon: ArrowLeftRight,
        className: 'md:col-span-1 lg:col-span-3',
    },
    {
        title: 'Adaptive Scheduling',
        description: 'Let AI optimize your calendar. Automatically balance work, breaks, and personal time for a harmonious and productive day.',
        icon: Calendar,
        className: 'md:col-span-1 lg:col-span-2',
    },
];

export default function Features() {
    return (
        <section className="bg-background relative">
            <div className="flex flex-col mx-auto px-4 py-12 sm:pt-24 sm:pb-32 w-full">
                <div className="relative mb-12">
                    <VideoPlaceholder />
                </div>
                <h2 className="text-3xl sm:text-4xl font-bold text-center mb-4 sm:mb-8 text-foreground">Elevate Your Productivity</h2>
                <p className="text-lg sm:text-xl text-center mb-8 sm:mb-12 text-muted-foreground max-w-2xl mx-auto">
                    Harness the power of AI to transform your daily routine. Our suite of intelligent features is designed to help you work smarter,
                    not harder.
                </p>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4 sm:gap-6 w-full mx-auto h-full min-h-fit sm:max-w-5xl">
                    {features.map((feature, index) => (
                        <FeatureCard key={index} {...feature} />
                    ))}
                </div>
            </div>
        </section>
    );
}