import React, { useState, useEffect, useRef } from 'react';
import { LinkIcon, Link2Icon, User2Icon, RocketIcon, PlayCircleIcon, TrendingUpIcon, ClockIcon, TargetIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { NavLink as Link } from 'react-router-dom';
import { Progress } from '@/components/ui/progress';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

const steps = [
    {
        icon: <Link2Icon className="h-6 w-6" />,
        title: 'Sync Your Devices',
        description: 'Connect Mantra AI to your devices and apps seamlessly.',
        details:
            "Mantra AI supports a wide range of devices and popular productivity apps. Our one-click sync process ensures you're up and running in no time.",
        stats: {
            value: 99,
            label: 'of users sync in less than 2 minutes',
            icon: <ClockIcon className="h-4 w-4" />,
        },
    },
    {
        icon: <User2Icon className="h-6 w-6" />,
        title: 'Tailor Your Experience',
        description: 'AI learns your preferences for personalized recommendations.',
        details:
            'Our advanced AI algorithms analyze your work patterns and preferences to provide tailored suggestions that boost your productivity.',
        stats: {
            value: 40,
            label: 'increase in task completion after personalization',
            icon: <TrendingUpIcon className="h-4 w-4" />,
        },
    },
    {
        icon: <RocketIcon className="h-6 w-6" />,
        title: 'Boost Your Productivity',
        description: 'Enhance focus, manage time better, and improve well-being.',
        details: 'Experience a new level of productivity with AI-powered task prioritization, focus sessions, and wellness reminders.',
        stats: {
            value: 60,
            label: 'average productivity boost reported by our users',
            icon: <TargetIcon className="h-4 w-4" />,
        },
    },
];

function HowItWorks() {
    const [progress, setProgress] = useState(0);
    const [expandedItem, setExpandedItem] = useState(null);
    const [lastSelectedItem, setLastSelectedItem] = useState(null);
    const accordionRef = useRef(null);

    const handleAccordionChange = (value) => {
        setExpandedItem(value);
        setLastSelectedItem(value);
        if (value === null) {
            return;
        }
        const stepIndex = parseInt(value.split('-')[1]);
        setProgress((stepIndex + 1) * 33.33);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (accordionRef.current && !accordionRef.current.contains(event.target)) {
                setLastSelectedItem(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <section className="py-16 bg-background">
            <div className="w-full flex flex-col mx-auto px-4 max-w-5xl">
                <h2 className="text-3xl font-semibold text-center mb-12 text-foreground">How Mantra AI Works</h2>

                <div className="mb-8">
                    <Progress value={progress} className="w-full" />
                </div>

                <div className="space-y-6" ref={accordionRef}>
                    {steps.map((step, index) => (
                        <Card
                            key={index}
                            className={`border-none shadow-sm transition-all duration-300 bg-accent/50 hover:bg-accent ${
                                lastSelectedItem === `item-${index}` ? 'scale-105' : ''
                            }`}
                        >
                            <CardContent className="p-6">
                                <Accordion type="single" collapsible value={expandedItem} onValueChange={handleAccordionChange}>
                                    <AccordionItem value={`item-${index}`}>
                                        <AccordionTrigger className="flex items-center justify-between w-full">
                                            <div className="flex items-start">
                                                <div className="flex-shrink-0 mr-6">
                                                    <div className="p-2 bg-primary/20 rounded-md text-primary">{step.icon}</div>
                                                </div>
                                                <div className="text-left">
                                                    <h3 className="text-xl font-medium mb-2">{step.title}</h3>
                                                    <p className="text-muted-foreground">{step.description}</p>
                                                </div>
                                            </div>
                                        </AccordionTrigger>
                                        <AccordionContent>
                                            <div className="mt-6 pl-14 space-y-4">
                                                <p className="text-base leading-relaxed">{step.details}</p>
                                                <div className="bg-secondary/10 p-4 rounded-lg">
                                                    <TooltipProvider>
                                                        <Tooltip>
                                                            <TooltipTrigger>
                                                                <div className="flex items-center space-x-2">
                                                                    <div className="text-primary">{step.stats.icon}</div>
                                                                    <span className="text-2xl font-bold text-primary">{step.stats.value}%</span>
                                                                    <span className="text-sm text-muted-foreground">{step.stats.label}</span>
                                                                </div>
                                                            </TooltipTrigger>
                                                            <TooltipContent>
                                                                <p>Based on user feedback and internal metrics</p>
                                                            </TooltipContent>
                                                        </Tooltip>
                                                    </TooltipProvider>
                                                    <Progress value={step.stats.value} className="mt-2" />
                                                </div>
                                            </div>
                                        </AccordionContent>
                                    </AccordionItem>
                                </Accordion>
                            </CardContent>
                        </Card>
                    ))}
                </div>

                <div className="mt-12 text-center space-y-4">
                    <Button variant="outline" size="lg" className="mr-4" disabled>
                        <PlayCircleIcon className="mr-2 h-4 w-4" />
                        Watch Demo
                    </Button>
                    <Link to="/download" className="no-underline">
                        <Button variant="default" size="lg" className="text-md">
                            Get started today
                            <LinkIcon className="ml-2 h-4 w-4" />
                        </Button>
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default HowItWorks;
