import React from 'react';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Instagram, Linkedin } from 'lucide-react';
import MantraLogo from '@/assets/mantra-logo.svg?react';
import { Button } from '@/components/ui/button';

const footerItems = [
    {
        label: 'Product',
        items: [
            { to: '/#features', label: 'Features' },
            { to: '/#how-it-works', label: 'How It Works' },
        ],
    },
    {
        label: 'Company',
        items: [
            { to: '/pricing', label: 'Pricing' },
            { to: '/privacy', label: 'Privacy' },
        ],
    },
];

const socialLinks = [
    { icon: Linkedin, href: 'https://www.linkedin.com/company/mantra-ai-official/', label: 'LinkedIn' },
    { icon: Instagram, href: 'https://instagram.com/mantraai', label: 'Instagram' },
];

const Footer = () => {
    return (
        <motion.footer
            className="bg-background py-12 border-t w-full"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <div className="flex flex-col w-full mx-auto px-4">
                <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
                    <div className="flex flex-col items-start">
                        <NavLink to="/" className="flex items-center space-x-2 mb-4">
                            <MantraLogo className="h-8 w-8 fill-card-foreground" />
                            <span className="text-xl font-semibold text-foreground">Mantra</span>
                        </NavLink>
                        <p className="text-sm text-muted-foreground mb-4">Empowering productivity through AI-driven solutions.</p>
                        <Button variant="outline" asChild>
                            <NavLink to="/download">Get Mantra free</NavLink>
                        </Button>
                    </div>

                    {footerItems.map((section, index) => (
                        <div key={section.label}>
                            <h3 className="font-semibold mb-4">{section.label}</h3>
                            <ul className="space-y-2">
                                {section.items.map((item) => (
                                    <li key={item.to}>
                                        <NavLink to={item.to} className="text-sm text-muted-foreground hover:text-foreground transition-colors">
                                            {item.label}
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}

                    <div>
                        <h3 className="font-semibold mb-4">Connect</h3>
                        <div className="flex space-x-4">
                            {socialLinks.map((link) => (
                                <a
                                    key={link.label}
                                    href={link.href}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-muted-foreground hover:text-foreground transition-colors"
                                >
                                    <link.icon className="h-5 w-5" />
                                    <span className="sr-only">{link.label}</span>
                                </a>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="mt-12 pt-8 border-t text-center text-sm text-muted-foreground">
                    <p>&copy; {new Date().getFullYear()} Mantra AI. All rights reserved.</p>
                </div>
            </div>
        </motion.footer>
    );
};

export default Footer;
