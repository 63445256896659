export type Platform = 'windows' | 'mac-arm' | 'mac-intel' | 'linux' | 'unknown';

export function detectPlatform(): Platform {
  if (typeof window === 'undefined') {
    return 'unknown';
  }

  const userAgent = window.navigator.userAgent.toLowerCase();
  const platform = window.navigator.platform.toLowerCase();

  if (userAgent.indexOf("win") > -1) return "windows";
  if (userAgent.indexOf("mac") > -1) {
    // Try to detect ARM Mac using WebGL renderer
    const canvas = document.createElement('canvas');
    const gl = canvas.getContext('webgl');
    if (gl) {
      const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
      if (debugInfo) {
        const renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL).toLowerCase();
        console.log('renderer', renderer);
        if (renderer.includes('apple m')) {
          return "mac-arm";
        }
      }
    }
    return "mac-intel";
  }
  if (userAgent.indexOf("linux") > -1) return "linux";

  return "unknown";
}