
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import DownloadSection from '@/components/sections/download/DownloadSection';
import PreviousVersionsSection from '@/components/sections/download/PreviousVersions';
import FeaturesSection from '@/components/sections/download/DownloadFeatures';
import CallToActionSection from '@/components/sections/download/DownloadCallToAction';
import { detectPlatform } from '@/lib/platformUtils';



const DownloadPage = () => {
    const [releaseData, setReleaseData] = useState(null);
    const [detectedPlatform, setDetectedPlatform] = useState('unknown');

    useEffect(() => {
        const fetchLatestRelease = async () => {
            try {
                const response = await fetch('https://api.github.com/repos/MantraAI/Mantra/releases/latest');
                const data = await response.json();
                setReleaseData(data);
            } catch (error) {
                console.error('Failed to fetch latest release:', error);
            }
        };
        setDetectedPlatform(detectPlatform());
        fetchLatestRelease();
    }, []);

    return (
        <div className="min-h-screen">
            <section className="py-20 bg-gradient-to-b from-background to-secondary" id="hero">
                <div className="container mx-auto px-4">
                    <motion.h1
                        className="text-4xl md:text-5xl font-bold text-center mb-6"
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        Download Mantra AI for Desktop
                    </motion.h1>
                    <motion.p
                        className="text-xl text-center mb-12 max-w-2xl mx-auto"
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                    >
                        Choose your operating system and start your journey to peak performance today.
                    </motion.p>
                </div>
            </section>
            <DownloadSection releaseData={releaseData} detectedPlatform={detectedPlatform} />
            <PreviousVersionsSection detectedPlatform={detectedPlatform} />
            <FeaturesSection />
            <CallToActionSection />
        </div>
    );
};

export default DownloadPage;