import React, { useState, useEffect } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Button } from '@/components/ui/button';
import { DownloadIcon } from '@radix-ui/react-icons';
import { Platform } from '@/lib/platformUtils';

interface Release {
    tag_name: string;
    published_at: string;
    assets: {
        name: string;
        browser_download_url: string;
        size: number;
    }[];
}

interface PreviousVersionsSectionProps {
    detectedPlatform: Platform;
}

const PreviousVersionsSection: React.FC<PreviousVersionsSectionProps> = ({ detectedPlatform }) => {
    const [releases, setReleases] = useState<Release[]>([]);
    const [selectedPlatform, setSelectedPlatform] = useState<Platform>(detectedPlatform);

    useEffect(() => {
        setSelectedPlatform(detectedPlatform);
    }, [detectedPlatform]);

    useEffect(() => {
        const fetchReleases = async () => {
            try {
                const response = await fetch('https://api.github.com/repos/MantraAI/Mantra/releases');
                const data: Release[] = await response.json();
                setReleases(data);
            } catch (error) {
                console.error('Failed to fetch releases:', error);
            }
        };

        fetchReleases();
    }, []);

    const getPlatformAssets = (platform: Platform) => {
        return releases.map(release => {
            let asset;
            switch (platform) {
                case 'windows':
                    asset = release.assets.find(a => a.name.endsWith('.exe'));
                    break;
                case 'mac-arm':
                    asset = release.assets.find(a => a.name.includes('arm64') && a.name.endsWith('.dmg'));
                    break;
                case 'mac-intel':
                    asset = release.assets.find(a => !a.name.includes('arm64') && a.name.endsWith('.dmg'));
                    break;
            }
            return asset ? { ...release, asset } : null;
        }).filter(release => release !== null);
    };

    const formatDate = (dateString: string) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    const formatSize = (bytes: number) => {
        return (bytes / 1024 / 1024).toFixed(2) + ' MB';
    };

    return (
        <section className="py-16 bg-gradient-to-b to-surface from-secondary pb-16 hidden md:flex">
            <div className="container mx-auto px-4 max-w-5xl">
                <h2 className="text-3xl font-bold text-center mb-4">Previous Versions</h2>
                <Tabs value={selectedPlatform} onValueChange={(value) => setSelectedPlatform(value as Platform)}>
                    <TabsList className="grid w-full grid-cols-3">
                        <TabsTrigger value="windows">Windows</TabsTrigger>
                        <TabsTrigger value="mac-arm">macOS (Apple Silicon)</TabsTrigger>
                        <TabsTrigger value="mac-intel">macOS (Intel)</TabsTrigger>
                    </TabsList>
                    {['windows', 'mac-arm', 'mac-intel'].map((platform) => (
                        <TabsContent key={platform} value={platform} className='pt-1'>
                            <div className="relative">
                                <Table className='mb-0.5'>
                                    <TableHeader>
                                        <TableRow className="border-none">
                                            <TableHead className="w-1/4 bg-background rounded-l-lg">Version</TableHead>
                                            <TableHead className="w-1/4 bg-background">Release Date</TableHead>
                                            <TableHead className="w-1/4 bg-background">Size</TableHead>
                                            <TableHead className="w-1/4 bg-background rounded-r-lg">Download</TableHead>
                                        </TableRow>
                                    </TableHeader>
                                </Table>
                                <div className="max-h-[600px] overflow-y-auto">
                                    <Table>
                                        <TableBody>
                                            {getPlatformAssets(platform as Platform).map((release, index) => (
                                                <TableRow key={index}>
                                                    <TableCell className="w-1/4">{release!.tag_name}</TableCell>
                                                    <TableCell className="w-1/4">{formatDate(release!.published_at)}</TableCell>
                                                    <TableCell className="w-1/4">{formatSize(release!.asset.size)}</TableCell>
                                                    <TableCell className="w-1/4">
                                                        <Button size="sm" asChild>
                                                            <a href={release!.asset.browser_download_url} download>
                                                                <DownloadIcon className="mr-2 h-4 w-4" />
                                                                Download
                                                            </a>
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </div>
                            </div>
                        </TabsContent>
                    ))}
                </Tabs>
            </div>
        </section>
    );
};

export default PreviousVersionsSection;