import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Menu, X, Sun, Moon, ChevronDown } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import MantraLogo from '@/assets/mantra-logo.svg?react';
import { motion, useScroll, useMotionValueEvent, AnimatePresence } from 'framer-motion';
import { cn } from '@/lib/utils';
import {useTheme } from '@/components/layout/theme-provider';
const navItems = [
    {
        label: 'Product',
        items: [
            { to: '/#features', label: 'Features' },
            { to: '/#how-it-works', label: 'How It Works' },
        ],
    },
    // { label: 'Teams', to: '/teams' },
    // { label: 'Individuals', to: '/individuals' },
    { label: 'Download', to: '/download' },
    { label: 'Pricing', to: '/pricing' },
];

export default function ModernAINav() {
    const { theme, setTheme} = useTheme();
    const [isScrolled, setIsScrolled] = useState(false);
    const { scrollY } = useScroll();

    useMotionValueEvent(scrollY, 'change', (latest) => {
        setIsScrolled(latest > 50);
    });

    const toggleTheme = () => {
        setTheme(theme === 'dark' ? 'light' : 'dark');
    
        };

    const handleNavigation = (to) => {
        if (to.startsWith('/#')) {
            const id = to.substring(2);
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };

    return (
        <motion.header
            className="fixed top-0 z-50 w-full flex items-center justify-center"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <motion.div
                layout
                className={cn(
                    'mx-auto px-4 bg-background transition-all duration-300 ease-in-out overflow-hidden',
                    isScrolled ? 'mt-4 max-w-6xl rounded-full shadow-lg mx-4' : 'w-full border-b'
                )}
            >
                <motion.div layout className="flex items-center justify-between h-16 w-full gap-8" >
                    <motion.div layout>
                        <NavLink to="/" className="flex items-center space-x-2">
                            <MantraLogo className="h-8 w-8 fill-card-foreground" />
                            <span className="text-xl font-semibold text-foreground">Mantra</span>
                        </NavLink>
                    </motion.div>
                    <motion.nav layout className="hidden lg:flex space-x-1">
                        {navItems.map((item, index) => (
                            <motion.div
                                key={item.label}
                                className="relative"
                                initial={{ opacity: 0, y: -20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: 0.1 * (index + 1), duration: 0.5 }}
                            >
                                {item.items ? (
                                    <DropdownMenu>
                                        <DropdownMenuTrigger asChild>
                                            <Button variant="ghost" className="text-sm font-medium">
                                                {item.label} <ChevronDown className="ml-1 h-4 w-4" />
                                            </Button>
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent>
                                            {item.items.map((subItem) => (
                                                <DropdownMenuItem key={subItem.to} asChild>
                                                    <NavLink 
                                                        to={subItem.to} 
                                                        onClick={() => handleNavigation(subItem.to)}
                                                    >
                                                        {subItem.label}
                                                    </NavLink>
                                                </DropdownMenuItem>
                                            ))}
                                        </DropdownMenuContent>
                                    </DropdownMenu>
                                ) : (
                                    <Button variant="ghost" asChild className="text-sm font-medium">
                                        <NavLink to={item.to}>{item.label}</NavLink>
                                    </Button>
                                )}
                            </motion.div>
                        ))}
                    </motion.nav>
                    <motion.div layout className="flex items-center gap-2">
                        <Button variant="ghost" size="icon" onClick={toggleTheme}>
                            {theme === "dark" ? <Sun className="h-5 w-5" /> : <Moon className="h-5 w-5" />}
                        </Button>
                        <NavLink to="/#contact">

                        <Button variant="ghost" className="hidden lg:inline-flex">
                            Request a demo
                        </Button>
                        </NavLink>

                        <motion.div layout>
                            <NavLink to="/download">
                            <Button
                                variant="default"
                                className={cn('hidden lg:inline-flex transition-all duration-100', isScrolled ? 'rounded-full' : 'rounded-md')}
                            >
                                Get Mantra free
                            </Button>
                            </NavLink>
                        </motion.div>
                        <Sheet>
                            <SheetTrigger asChild>
                                <Button variant="ghost" size="icon" className="lg:hidden">
                                    <Menu className="h-5 w-5" />
                                </Button>
                            </SheetTrigger>
                            <SheetContent side="right" className="w-[300px] sm:w-[400px]">
                                <nav className="flex flex-col space-y-4 pt-4">
                                    {navItems.map((item) => (
                                        <div key={item.label}>
                                            {item.items ? (
                                                <DropdownMenu>
                                                    <DropdownMenuTrigger asChild>
                                                        <Button variant="ghost" className="w-full justify-start">
                                                            {item.label} <ChevronDown className="ml-2 h-4 w-4" />
                                                        </Button>
                                                    </DropdownMenuTrigger>
                                                    <DropdownMenuContent>
                                                        {item.items.map((subItem) => (
                                                            <DropdownMenuItem key={subItem.to} asChild>
                                                                <NavLink 
                                                                    to={subItem.to}
                                                                    onClick={() => handleNavigation(subItem.to)}
                                                                >
                                                                    {subItem.label}
                                                                </NavLink>
                                                            </DropdownMenuItem>
                                                        ))}
                                                    </DropdownMenuContent>
                                                </DropdownMenu>
                                            ) : (
                                                <Button variant="ghost" asChild className="w-full justify-start">
                                                    <NavLink to={item.to}>{item.label}</NavLink>
                                                </Button>
                                            )}
                                        </div>
                                    ))}
                                </nav>
                            </SheetContent>
                        </Sheet>
                    </motion.div>
                </motion.div>
            </motion.div>
            <AnimatePresence>
                {!isScrolled && (
                    <motion.div
                        className="absolute bottom-0 left-0 h-0.5 bg-foreground"
                        initial={{ scaleX: 0 }}
                        animate={{ scaleX: 1 }}
                        exit={{ scaleX: 0 }}
                        transition={{ duration: 0.3 }}
                    />
                )}
            </AnimatePresence>
        </motion.header>
    );
}