import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '@/components/ui/card';
import { useToast } from '@/components/ui/use-toast';
import { Label } from '@/components/ui/label';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Separator } from '@/components/ui/separator';
import { Mail, Twitter, Linkedin, Instagram, MessageSquare, Clock } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar';
import { ExternalLink } from 'lucide-react';
const socialLinks = [
    { icon: Twitter, link: '', label: 'Twitter' },
    { icon: Linkedin, link: 'https://www.linkedin.com/company/mantra-ai-official/', label: 'LinkedIn' },
    { icon: Instagram, link: '', label: 'Instagram' },
];

function Contact() {
    const { toast } = useToast();
    const [errors, setErrors] = useState({ name: '', email: '', subject: '', message: '' });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const validateForm = (data) => {
        let formIsValid = true;
        let errors = { name: '', email: '', subject: '', message: '' };

        if (!data.get('name')?.toString().trim()) {
            errors.name = 'Name is required';
            formIsValid = false;
        }

        const email = data.get('email')?.toString().trim();
        if (!email) {
            errors.email = 'Email is required';
            formIsValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email is invalid';
            formIsValid = false;
        }

        if (!data.get('subject')?.toString().trim()) {
            errors.subject = 'Subject is required';
            formIsValid = false;
        }

        if (!data.get('message')?.toString().trim()) {
            errors.message = 'Message is required';
            formIsValid = false;
        }

        setErrors(errors);
        return formIsValid;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.target;
        const data = new FormData(form);

        if (!validateForm(data)) {
            toast({
                title: 'Validation Error',
                description: 'Please fill out all fields correctly.',
                variant: 'destructive',
            });
            return;
        }

        setIsSubmitting(true);

        try {
            const response = await fetch('https://formspree.io/f/xkgwbegn', {
                method: 'POST',
                body: data,
                headers: {
                    Accept: 'application/json',
                },
            });

            if (response.ok) {
                toast({
                    title: 'Success',
                    description: 'Your message has been sent successfully!',
                });
                form.reset();
            } else {
                toast({
                    title: 'Submission Error',
                    description: 'Failed to send your message. Please try again.',
                    variant: 'destructive',
                });
            }
        } catch (error) {
            toast({
                title: 'Error',
                description: 'An error occurred while submitting the form.',
                variant: 'destructive',
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <section className="py-20 bg-gradient-to-b from-background to-secondary">
            <div className="flex w-full md:max-w-5xl flex-col  mx-auto px-4">
                <motion.h2
                    className="text-4xl font-bold text-center mb-12 text-foreground"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    Get in Touch
                </motion.h2>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 h-fit">
                    <motion.div initial={{ opacity: 0, x: -20 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 0.5, delay: 0.2 }} className='h-full'>
                        <Card>
                            <CardHeader>
                                <CardTitle>Send Us a Message</CardTitle>
                            </CardHeader>
                            <CardContent>
                                <form onSubmit={handleSubmit} className="space-y-4">
                                    <div className="space-y-1">
                                        <Label htmlFor="name">Name</Label>
                                        <Input
                                            id="name"
                                            name="name"
                                            placeholder="Your Name"
                                            required
                                            className="bg-background"
                                            aria-invalid={errors.name ? 'true' : 'false'}
                                        />
                                        {errors.name && <p className="text-red-600 text-sm">{errors.name}</p>}
                                    </div>
                                    <div className="space-y-1">
                                        <Label htmlFor="email">Email</Label>
                                        <Input
                                            id="email"
                                            name="email"
                                            type="email"
                                            placeholder="Your Email"
                                            required
                                            className="bg-background"
                                            aria-invalid={errors.email ? 'true' : 'false'}
                                        />
                                        {errors.email && <p className="text-red-600 text-sm">{errors.email}</p>}
                                    </div>
                                    <div className="space-y-1">
                                        <Label htmlFor="subject">Subject</Label>
                                        <Input
                                            id="subject"
                                            name="subject"
                                            placeholder="Subject"
                                            required
                                            className="bg-background"
                                            aria-invalid={errors.subject ? 'true' : 'false'}
                                        />
                                        {errors.subject && <p className="text-red-600 text-sm">{errors.subject}</p>}
                                    </div>
                                    <div className="space-y-1">
                                        <Label htmlFor="message">Message</Label>
                                        <Textarea
                                            id="message"
                                            name="message"
                                            placeholder="Your Message"
                                            required
                                            className="min-h-[150px] bg-background"
                                            aria-invalid={errors.message ? 'true' : 'false'}
                                        />
                                        {errors.message && <p className="text-red-600 text-sm">{errors.message}</p>}
                                    </div>
                                    <Button type="submit" className="w-full" disabled={isSubmitting}>
                                        {isSubmitting ? 'Sending...' : 'Send Message'}
                                    </Button>
                                </form>
                            </CardContent>
                        </Card>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, x: 20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5, delay: 0.4 }}
                        className="space-y-8 h-full"
                    >
                        <Card className='h-full flex flex-col'>
                            <CardHeader>
                                <CardTitle className="flex items-center gap-2">
                                    Connect With Us
                                    <Badge variant="secondary">We're here to help!</Badge>
                                </CardTitle>
                                <CardDescription>Reach out through various channels</CardDescription>
                            </CardHeader>
                            <CardContent className="flex flex-col gap-6 h-full max-h-full">
                                <div className="flex items-center gap-4">
                                    <Avatar>
                                        <AvatarImage src="/api/placeholder/400/400" alt="Support Team" />
                                        <AvatarFallback>ST</AvatarFallback>
                                    </Avatar>
                                    <div>
                                        <h3 className="font-semibold">Our Support Team</h3>
                                        <p className="text-sm text-muted-foreground">Always ready to assist you</p>
                                    </div>
                                </div>

                                <Alert className="flex items-center justify-between">
                                    <div className="flex items-center gap-2">
                                        <Mail className="h-4 w-4" />
                                        <AlertDescription>vashist.aadvik@gmail.com</AlertDescription>
                                    </div>
                                    <Button variant="outline" size="sm" className="flex items-center gap-1">
                                        <ExternalLink className="h-3 w-3" />
                                        
                                        <span className='hidden sm:flex'> Email Us
                                            </span>
                                    </Button>
                                </Alert>

                                <div>
                                    <h4 className="font-semibold mb-2">Follow Us</h4>
                                    <div className="flex space-x-4">
                                        {socialLinks.map(({ icon: Icon, link, label }) => {
                                            if (!link) {
                                                return (
                                                    <Button
                                                        key={label}
                                                        variant="outline"
                                                        size="icon"
                                                        disabled
                                                        aria-label={`${label} (not available)`}
                                                    >
                                                        <Icon className="h-4 w-4" />
                                                    </Button>
                                                );
                                            }

                                            return (
                                                <Button key={label} variant="outline" size="icon" asChild>
                                                    <a href={link} target="_blank" rel="noopener noreferrer" aria-label={`Follow us on ${label}`}>
                                                        <Icon className="h-4 w-4" />
                                                    </a>
                                                </Button>
                                            );
                                        })}{' '}
                                    </div>
                                </div>

                                <Separator className='mt-auto' />

                                <div>
                                    <h4 className="font-semibold mb-2">Quick Links</h4>
                                    <div className="grid grid-cols-2 gap-2">
                                        <Button variant="link" className="justify-start">
                                            <MessageSquare className="h-4 w-4 mr-2" /> FAQs
                                        </Button>
                                        <Button variant="link" className="justify-start">
                                            <Clock className="h-4 w-4 mr-2" /> Support Hours
                                        </Button>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </motion.div>
                </div>
            </div>
        </section>
    );
}

export default Contact;
