import React from 'react';
import { motion } from 'framer-motion';
import { CheckCircledIcon } from '@radix-ui/react-icons';

const FeaturesSection: React.FC = () => {
    const features = [
        'Full-featured AI-powered productivity suite',
        'Seamless cross-device sync with mobile app',
        'Advanced analytics dashboard',
        'Offline mode for uninterrupted work',
        'Custom integrations with popular tools',
        'Regular updates and new features',
    ];

    return (
        <div className="w-full h-fit min-h-fit min-w-fit bg-card pt-12 pb-16 px-4">
        <motion.div
            className="max-w-4xl mx-auto"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.8 }}
        >
            <h3 className="text-2xl lg:text-4xl font-semibold text-center mb-6 lg:mb-10 ">Why Choose Mantra AI for Desktop?</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
                {features.map((feature, index) => (
                    <div key={index} className="flex items-center">
                        <CheckCircledIcon className="w-5 h-5 mr-2 text-primary" />
                        <span className='text-md lg:text-lg nowrap'>{feature}</span>
                    </div>
                ))}
            </div>
        </motion.div>
        </div>

    );
};

export default FeaturesSection;