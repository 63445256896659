import React, { useEffect, useState } from 'react';
import Demo from '@/assets/demo-light.png';
import DemoDark from '@/assets/demo-dark.png';
import { useTheme } from '@/components/layout/theme-provider';
export function VideoPlaceholder() {
    return (
        <div className="relative w-full max-w-[1920px] bg-transparent">
            <div className="relative sm:mx-10 lg:mx-32 aspect-[32/9]" />
        </div>
    );
}

export function Video() {
    const { theme } = useTheme();
    const [video, setVideo] = useState(theme === 'dark' ? DemoDark : Demo);
    useEffect(() => {
        const newVid = theme === 'dark' ? DemoDark : Demo;
        setVideo(newVid);
        console.log('Theme changed:', theme);
    }, [theme]);
    return (
        <div className="absolute left-1/2 top-full transform -translate-x-1/2 -translate-y-1/2 z-[10000] w-full max-w-[1920px] bg-transparent">
            <div className="relative sm:mx-10 lg:mx-32 aspect-video bg-accent rounded-2xl p-0">
                <img src={video} alt="Video placeholder" className="w-full h-full object-cover shadow-xl rounded-2xl" />
            </div>
        </div>
    );
}

export default Video;
