'use client';
import React from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { QuoteIcon } from '@radix-ui/react-icons';
import { InfiniteMovingCards } from '@/components/ui/infinite-moving-cards';

const testimonials = [
    {
        quote: "Mantra AI has revolutionized my workflow. I'm more productive than ever!",
        name: 'Tina Chen',
        role: 'Marketing Executive',
        avatar: '/path/to/avatar1.jpg',
    },
    {
        quote: "The personalized recommendations are spot-on. It's like having a personal productivity coach.",
        name: 'Sam Lee',
        role: 'Freelance Designer',
        avatar: '/path/to/avatar2.jpg',
    },
    {
        quote: 'Balancing studies and part-time work became so much easier with Mantra AI.',
        name: 'Emily Chen',
        role: 'Graduate Student',
        avatar: '/path/to/avatar3.jpg',
    },
    {
        quote: "As a startup founder, Mantra AI helps me stay focused on what truly matters. It's a game-changer.",
        name: 'Raj Patel',
        role: 'Tech Entrepreneur',
        avatar: '/path/to/avatar4.jpg',
    },
    {
        quote: 'The meditation features have significantly improved my stress management and overall well-being.',
        name: 'Laura Martinez',
        role: 'HR Professional',
        avatar: '/path/to/avatar5.jpg',
    },
    {
        quote: "My productivity has soared since I started using Mantra AI's Adaptive Focus Flows.",
        name: 'Sierra Collis',
        role: 'Undergraduate Student',
        avatar: '/path/to/avatar3.jpg',
    },
];

function Testimonials() {
    return (
        <section className="py-20 bg-gradient-to-b from-background to-secondary">
            <div className="container mx-auto px-4 max-w-full">
                <h2 className="text-3xl font-bold text-center mb-12 text-foreground">What Our Users Say</h2>
                <InfiniteMovingCards speed={'slow'} className='h-fit flex flex-row'>
                    {testimonials.map((testimonial, index) => (
                        <Card key={index} className="w-full max-w-[50vw] md:max-w-md flex select-none h-full ">
                            <CardContent className="flex flex-col items-center  pt-4  p-4 md:p-6 md:pt-6">
                                <QuoteIcon className="h-8 w-8 text-primary mb-4" />
                                <p className="text-center mb-4">{testimonial.quote}</p>
                                <Avatar className="w-12 h-12 mb-2 hidden md:block">
                                    <AvatarImage src={testimonial.avatar} alt={testimonial.name} />
                                    <AvatarFallback>{testimonial.name[0]}</AvatarFallback>
                                </Avatar>
                                <p className="font-semibold mt-auto">{testimonial.name}</p>
                                <p className="text-sm text-muted-foreground">{testimonial.role}</p>
                            </CardContent>
                        </Card>
                    ))}
                </InfiniteMovingCards>
            </div>
        </section>
    );
}

export default Testimonials;
